const environment = {
  BACKEND_BASE_URL: "http://localhost:8080",
  SWAP_ADDRESS:{
    56:"0x81D48238F19bCDc08C3891e26B35B5002ac4C2bE"
  },
DEFUALT_CHAIN:56,
ROUTER:"0x10ed43c718714eb63d5aa57b78b54704e256024e",
WETH:"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
};
export default environment;
