export const Images = {
  web3: {
    metamask: require("./web3/metamask.png"),
    wallet: require("./web3/wallet.png"),
  },
  login: {
    bg: require("./images/bg-img.png"),
  },
};

export { default as homebg } from "./images/homebg.png";
export { default as logo } from "./images/logo.svg";
export { default as nodata } from "./images/nodata.svg";


export { default as rightArrow } from "./images/arrow.png";
export { default as swapicon } from "./images/swapicon.png";
export { default as tokenicon } from "./images/token.png";
export { default as btcicon } from "./images/btc.png";
export { default as ethicon } from "./images/eth.png";
export { default as ltcicon } from "./images/ltc.png";
export { default as ustdcicon } from "./images/usdt.png";



export { default as avax } from "./images/avalanche-avax.png";
export { default as bnb } from "./images/bnb.png";
export { default as cardona } from "./images/cardano-ada.png";
export { default as degecoin } from "./images/dogecoin-doge.png";
export { default as pancake } from "./images/pancakeswap-cake.png";
export { default as solana } from "./images/solana-sol.png";
export { default as usdc } from "./images/usd-coin-usdc.png";
 




