import { MainCol, MainContainer, MainRow, ToastMessage } from "../common";
import NavbarComp from "components/common/navbar";
import { CardDiv, MainSection, Name, StartButton, Title } from "./elements";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import {
  rightArrow,
  swapicon,
  tokenicon,
  btcicon,
  ethicon,
  ltcicon,
  ustdcicon,
  avax,
  bnb,
  cardona,
  degecoin,
  pancake,
  usdc,
  solana,
} from "assets";
import { Select, Space } from "antd";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { CommonUtility } from "utility/common";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { swapAbi } from "utility/abis/swapAbi";
import environment from "environment";
import { useEffect } from "react";
import Web3 from "web3";
import Loading from "components/common/loaders/loading";
import {
  ChainId,
  Token,
  WETH,
  Fetcher,
  Route,
  Trade,
  TokenAmount,
  TradeType,
} from "@pancakeswap-libs/sdk";

import {} from "@pancakeswap/sdk";
import { JsonRpcProvider } from "@ethersproject/providers";
import { routerAbi } from "utility/abis/router02Abi";
import { erc20Abi } from "utility/abis/erc20";
const CustomTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    color: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    width: "100%",
    borderColor: "#37404a",
    boxShadow: "none",
    "&:hover": {
      "& fieldset": {
        borderColor: "#37404a",
      },
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#37404a !important",
  },
  "& .MuiInputBase-input": {
    color: "#fff",
  },
});

const HomeCom = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { Option } = Select;
  const [sendToken, setSendToken] = useState(null);
  const [sendValue, setSendValue] = useState(null);
  const [recieveToken, setRecieveToken] = useState(null);
  const [recieveValue, setRecieveValue] = useState(null);
  const [interchange, setInterchange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const swapHandle = () => {
    // Swap the values
    const tempValue = sendValue;
    setSendValue(recieveValue);
    setRecieveValue(tempValue);

    // Swap the tokens
    const tempToken = sendToken;
    setSendToken(recieveToken);
    setRecieveToken(tempToken);
    setInterchange(!interchange);
  };

  let tokens = [
    {
      name: "ONS",
      icon: tokenicon,
      value: 0,
      decimals: 18,
      address: "0x57FC32015c09eF510584648b89BfE42642CD2d76",
    },
    {
      name: "BNB",
      icon: bnb,
      value: 1,
      decimals: 18,
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    },
    {
      name: "Solana",
      icon: solana,
      value: 2,
      decimals: 18,
      address: "0x570A5D26f7765Ecb712C0924E4De545B89fD43dF",
    },
    {
      name: "DOGE",
      icon: degecoin,
      value: 3,
      decimals: 18,
      address: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
    },
    {
      name: "Pancake",
      icon: pancake,
      value: 4,
      decimals: 18,
      address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    },
    {
      name: "Cardona",
      icon: cardona,
      value: 5,
      decimals: 18,
      address: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
    },
    {
      name: "AVAX",
      icon: avax,
      value: 6,
      decimals: 18,
      address: "0x1CE0c2827e2eF14D5C4f29a091d735A204794041",
    },
    {
      name: "USDT",
      icon: ustdcicon,
      value: 7,
      decimals: 18,
      address: "0x55d398326f99059fF775485246999027B3197955",
    },

    {
      name: "ETH",
      icon: ethicon,
      value: 8,
      decimals: 18,
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    },
    {
      name: "USDC",
      icon: usdc,
      value: 9,
      decimals: 18,
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    },

    {
      name: "BTC",
      icon: btcicon,
      value: 10,
      decimals: 18,
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    },
    //and xrpis missing
    // {
    //   name: "LTC",
    //   icon: ltcicon,
    //   value: 11,
    //   decimals: 18,
    //   address: "0x57FC32015c09eF510584648b89BfE42642CD2d76",
    // },
  ];
  const getAddressByTokenName = (tokenName) => {
    switch (tokenName) {
      case "ONS":
        return {
          address: "0x57FC32015c09eF510584648b89BfE42642CD2d76",
          index: 0,
          decimal: 18,
        };
      case "BNB":
        return {
          address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
          index: 1,
          decimal: 18,
        };
      case "Solana":
        return {
          address: "0x570A5D26f7765Ecb712C0924E4De545B89fD43dF",
          index: 2,
          decimal: 18,
        };
      case "DOGE":
        return {
          address: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
          index: 3,
          decimal: 8,
        };
      case "Pancake":
        return {
          address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
          index: 4,
          decimal: 18,
        };
      case "Cardona":
        return {
          address: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
          index: 5,
          decimal: 18,
        };
      case "AVAX":
        return {
          address: "0x1CE0c2827e2eF14D5C4f29a091d735A204794041",
          index: 6,
          decimal: 18,
        };
      case "USDT":
        return {
          address: "0x55d398326f99059fF775485246999027B3197955",
          index: 7,
          decimal: 18,
        };
      case "ETH":
        return {
          address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
          index: 8,
          decimal: 18,
        };
      case "USDC":
        return {
          address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
          index: 9,
          decimal: 18,
        };
      case "BTC":
        return {
          address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
          index: 10,
          decimal: 18,
        };
      case "XRP":
        return { address: "YOUR_XRP_ADDRESS", index: 11 };
      default:
        return null; // Return null if token name not found
    }
  };
  const estimateAMount = async (sendToken, receiveToken) => {
    try {
      setIsLoading(true);
      const web3 = new Web3(walletProvider);
      const contract = CommonUtility.contract(
        web3,
        routerAbi,
        environment.ROUTER
      );
      // const abc = await contract.methods.onsToken().call();
      // console.log("ABC", abc);
      const sendData = getAddressByTokenName(sendToken);
      const receiveData = getAddressByTokenName(receiveToken);
      const amount = CommonUtility.convertToWei(
        sendValue.toString(),
        sendData?.decimal
      );
      let estimate;
      if (
        (sendToken == "ONS" && receiveToken == "BNB") ||
        (receiveToken == "ONS" && sendToken == "BNB")
      ) {
        estimate = await contract.methods
          .getAmountsOut(amount, [sendData.address, receiveData.address])
          .call();
      } else {
        estimate = await contract.methods
          .getAmountsOut(amount, [
            sendData.address,
            environment.WETH,
            receiveData.address,
          ])
          .call();
      }

      console.log("estimate", estimate);
      const valueToBeSet = CommonUtility.convertFromWei(
        estimate[estimate.length - 1].toString(),
        receiveData?.decimal
      );
      setRecieveValue(valueToBeSet);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("estimation error", error);
    }
  };
  useEffect(() => {
    if (isConnected && sendToken && recieveToken && sendValue) {
      estimateAMount(sendToken, recieveToken);
    }
  }, [isConnected, sendToken, recieveToken, sendValue]);
  // const doTxn = async () => {
  //   try {
  //     if (!isConnected )
  //       throw "Please connect your wallet";
  //       if (+chainId != environment.DEFUALT_CHAIN)
  //       throw "Please switch to BSC network";
  //     if(!sendToken || !recieveToken) throw "Please select tokens";
  //     if(!sendValue) throw "Please enter amount"
  //     setIsLoading(true);
  //     console.log("SEND TOKEN", sendToken, recieveToken);
  //     const web3 = new Web3(walletProvider);
  //     const sendData = getAddressByTokenName(sendToken);
  //     const receiveData = getAddressByTokenName(recieveToken);

  //     const contract = CommonUtility.contract(web3, erc20Abi, sendData.address);
  //     if (sendToken !== "BNB") {
  //       const balance = await contract.methods.balanceOf(address).call();
  //       console.log("USER BALANCE", balance);
  //       const amount = CommonUtility.convertToWei(sendValue, sendData.decimal);
  //       if (+balance < +amount) throw "Not enough Balance";
  //       const allowance = await contract.methods
  //         .allowance(
  //           address,
  //           environment.SWAP_ADDRESS[environment.DEFUALT_CHAIN]
  //         )
  //         .call();
  //       if (+allowance < +amount) {
  //         const totalSupply = await contract.methods.totalSupply().call()
  //         const approval = await contract.methods
  //           .approve(
  //             environment.SWAP_ADDRESS[environment.DEFUALT_CHAIN],
  //             totalSupply
  //           )
  //           .send({ from: address });
  //         if (approval && approval.code == 4001)
  //           throw "User denied the transaction";
  //         if (!approval.status) throw "Transaction Failed";
  //       }
  //     }

  //     let txn;
  //     const swapContract = CommonUtility.contract(
  //       web3,
  //       swapAbi,
  //       environment.SWAP_ADDRESS[environment.DEFUALT_CHAIN]
  //     );
  //     if (sendToken == "ONS" && recieveToken == "BNB") {
  //       txn = await swapContract.methods
  //         .swapOnsTokensForBnb(
  //           CommonUtility.convertToWei(sendValue, sendData.decimal)
  //         )
  //         .send({ from: address });
  //     } else if (recieveToken == "ONS" && sendToken == "BNB") {
  //       txn = await swapContract.methods.swapBnbForOnsTokens().send({
  //         from: address,
  //         value: CommonUtility.convertToWei(sendValue, sendData.decimal),
  //       });
  //     } else if (sendToken == "ONS" && recieveToken !== "BNB") {
  //       txn = await swapContract.methods
  //         .swapOnsForOtherTokens(
  //           sendData.index,
  //           receiveData.index,
  //           CommonUtility.convertToWei(sendValue, sendData.decimal)
  //         )
  //         .send({ from: address });
  //     } else if (recieveToken == "ONS" && sendToken !== "BNB") {
  //       txn = await swapContract.methods
  //         .swapOtherTokensForOnsToken(
  //           sendData.index,
  //           receiveData.index,
  //           CommonUtility.convertToWei(sendValue, sendData.decimal)
  //         )
  //         .send({ from: address });
  //     }
  //     if (txn && txn.code == 4001) throw "User denied the transaction";
  //     if (!txn.status) throw "Transaction Failed";
  //     setIsLoading(false);
  //     ToastMessage("Success!", "Transaction successful", "success");
  //   } catch (error) {
  //     console.log("Do txn func error", error);
  //     setIsLoading(false);
  //     if (error?.message) {
  //       ToastMessage("Error", error?.message, "error");
  //     } else {
  //       ToastMessage("Error", error, "error");
  //     }
  //   }
  // };

  const doTxn = async () => {
    try {
      if (!isConnected) throw "Please connect your wallet";
      if (+chainId != environment.DEFUALT_CHAIN)
        throw "Please switch to BSC network";
      if (!sendToken || !recieveToken) throw "Please select tokens";
      if (!sendValue) throw "Please enter amount";
      setIsLoading(true);
      console.log("SEND TOKEN", sendToken, recieveToken);
      const web3 = new Web3(walletProvider);
      const sendData = getAddressByTokenName(sendToken);
      const receiveData = getAddressByTokenName(recieveToken);

      const contract = CommonUtility.contract(web3, erc20Abi, sendData.address);
      if (sendToken !== "BNB") {
        const balance = await contract.methods.balanceOf(address).call();
        console.log("USER BALANCE", balance);
        const amount = CommonUtility.convertToWei(sendValue, sendData.decimal);
        if (+balance < +amount) throw "Not enough Balance";
        const allowance = await contract.methods
          .allowance(address, environment.ROUTER)
          .call();
        if (+allowance < +amount) {
          const totalSupply = await contract.methods.totalSupply().call();
          const approval = await contract.methods
            .approve(environment.ROUTER, totalSupply)
            .send({ from: address });
          if (approval && approval.code == 4001)
            throw "User denied the transaction";
          if (!approval.status) throw "Transaction Failed";
        }
      }

      const swapContract = CommonUtility.contract(
        web3,
        routerAbi,
        environment.ROUTER
      );
      // if (sendToken == "ONS" && recieveToken == "BNB") {
      //   txn = await swapContract.methods
      //     .swapOnsTokensForBnb(
      //       CommonUtility.convertToWei(sendValue, sendData.decimal)
      //     )
      //     .send({ from: address });
      // } else if (recieveToken == "ONS" && sendToken == "BNB") {
      //   txn = await swapContract.methods.swapBnbForOnsTokens().send({
      //     from: address,
      //     value: CommonUtility.convertToWei(sendValue, sendData.decimal),
      //   });
      // } else if (sendToken == "ONS" && recieveToken !== "BNB") {
      //   txn = await swapContract.methods
      //     .swapOnsForOtherTokens(
      //       sendData.index,
      //       receiveData.index,
      //       CommonUtility.convertToWei(sendValue, sendData.decimal)
      //     )
      //     .send({ from: address });
      // } else if (recieveToken == "ONS" && sendToken !== "BNB") {
      //   txn = await swapContract.methods
      //     .swapOtherTokensForOnsToken(
      //       sendData.index,
      //       receiveData.index,
      //       CommonUtility.convertToWei(sendValue, sendData.decimal)
      //     )
      //     .send({ from: address });
      // }
      console.log("USER REQUESTTT");
      let txn;
      if (sendToken !== "BNB" && recieveToken !== "BNB") {
        txn = await swapContract.methods
          .swapExactTokensForTokensSupportingFeeOnTransferTokens(
            CommonUtility.convertToWei(sendValue, sendData.decimal),
            1,
            [sendData.address, environment.WETH, receiveData.address],
            address,
            Math.floor(Date.now() / 1000) + 900
          )
          .send({ from: address });
      } else if (sendToken !== "BNB" && recieveToken == "BNB") {
        txn = await swapContract.methods
          .swapExactTokensForETHSupportingFeeOnTransferTokens( CommonUtility.convertToWei(sendValue, sendData.decimal),
            1,
            [sendData.address, receiveData.address],
            address,
            Math.floor(Date.now() / 1000) + 900
          )
          .send({
            from: address,
       
          });
      } else if (sendToken == "BNB" && recieveToken !== "BNB") {
        txn = await swapContract.methods
          .swapExactETHForTokensSupportingFeeOnTransferTokens(
            1,
            [sendData.address, receiveData.address],
            address,
            Math.floor(Date.now() / 1000) + 900
          )
          .send({
            from: address,
            value: CommonUtility.convertToWei(sendValue, sendData.decimal),
          });
      }

      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      setIsLoading(false);
      ToastMessage("Success!", "Transaction successful", "success");
    } catch (error) {
      console.log("Do txn func error", error);
      setIsLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  return (
    <>
      <NavbarComp />
      {isLoading && <Loading content={"Processing"} />}
      <MainSection>
        <MainContainer>
          <MainRow className="main-row">
            <MainCol lg={6}>
              <Title className="text-lg-start text-center">
                <span>ONS-AI SWAP</span> 
              </Title>
              <p className="textDescription text-lg-start text-center">
               Buy ONS Token - Stake and Earn Big with Onschain! <br />
               Remember man! <br />
               You are investing in the most valuable AI project of the Future!
              </p>
              <StartButton
                onClick={() => {
                  window.open("https://stake.onschain.com/");
                }}
              >
                Stake ONS <FaArrowRight />{" "}
              </StartButton>
            </MainCol>
            <MainCol lg={4}>
              <CardDiv>
                <div className="box" id="myBox2">
                  <div className="cardWrapper">
                    <Name>Swap</Name>
                    <p className="swapDescription">
                    Buy ONS Token and Change your Future!
                    </p>
                    <div className="" style={{ marginTop: "2.5rem" }}>
                      <div className="d-flex  gap-1">
                        <div style={{ width: "100%" }}>
                          <CustomTextField
                            id="outlined-required"
                            label="You Send"
                            type="number"
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                              className: "sendCurrencyTextField",
                            }}
                            sx={{
                              "& fieldset": { borderColor: "#37404a" },
                            }}
                            outlined={false}
                            onChange={(e) => setSendValue(e.target.value)}
                            value={sendValue}
                          />
                        </div>

                        {!interchange ? (
                          <Select
                            defaultValue={tokens[0].name}
                            style={{
                              width: 140,
                            }}
                            onSelect={(value) => {
                              setSendToken(value);
                            }}
                            value={sendToken}
                          >
                            {tokens.map((item) => (
                              <Option
                                key={item.name}
                                value={item.name}
                                disabled={item.value !== 0}
                              >
                                <img
                                  src={item.icon}
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 8 }}
                                />
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            defaultValue={tokens[0].name}
                            style={{
                              width: 140,
                            }}
                            onSelect={(value) => {
                              setSendToken(value);
                            }}
                            value={sendToken}
                          >
                            {tokens.map((item) => (
                              <Option
                                value={item.name}
                                disabled={item.value == 0}
                              >
                                <img
                                  src={item.icon}
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 8 }}
                                />
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </div>
                      <div className="d-flex justify-content-center">
                        <button className="swapIconBtn">
                          <img
                            src={swapicon}
                            width={20}
                            height={18}
                            onClick={swapHandle}
                          />
                        </button>
                      </div>

                      <div className="d-flex  gap-1   recieveToken">
                        <CustomTextField
                          id="outlined-required"
                          label="You Recieve"
                          type="number"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                            className: "sendCurrencyTextField",
                          }}
                          sx={{
                            "& fieldset": { borderColor: "#37404a" },
                          }}
                          outlined={false}
                          onChange={(e) => setRecieveValue(e.target.value)}
                          value={recieveValue}
                        />

                        {!interchange ? (
                          <Select
                            defaultValue={tokens[0].name}
                            style={{
                              width: 140,
                            }}
                            onSelect={(value) => {
                              setRecieveToken(value);
                            }}
                            value={recieveToken}
                          >
                            {tokens.map((item) => (
                              <Option
                                value={item.name}
                                disabled={item.value == 0}
                              >
                                <img
                                  src={item.icon}
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 8 }}
                                />
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            defaultValue={tokens[0].name}
                            style={{
                              width: 140,
                            }}
                            onSelect={(value) => {
                              setRecieveToken(value);
                            }}
                            value={recieveToken}
                          >
                            {tokens.map((item) => (
                              <Option
                                key={item.name}
                                value={item.name}
                                disabled={item.value !== 0}
                              >
                                <img
                                  src={item.icon}
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 8 }}
                                />
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </div>

                      <div className="d-flex justify-content-center">
                        <button className="swapBtn" onClick={() => doTxn()}>
                          Swap
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </CardDiv>
            </MainCol>
          </MainRow>
        </MainContainer>
      </MainSection>
    </>
  );
};

export default HomeCom;
