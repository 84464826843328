import React, { useState } from "react";
import { ButtonWrapper, ConnectBtn, MainSection } from "./elements";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { MainContainer } from "../mainStyle/mainStyle";
import { Image } from "react-bootstrap";
import { logo } from "assets";
import { CommonUtility } from "utility/common";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
const NavbarComp = () => {
  const chains = [
    //{
    //   chainId: 1,
    //   name: "Ethereum",
    //   currency: "ETH",
    //   explorerUrl: "https://etherscan.io",
    //   rpcUrl: "https://cloudflare-eth.com",
    // },
    // {
    //   chainId: 42161,
    //   name: "Arbitrum",
    //   currency: "ETH",
    //   explorerUrl: "https://arbiscan.io",
    //   rpcUrl: "https://arb1.arbitrum.io/rpc",
    // },
    {
      chainId: 56,
      name: "Binance",
      currency: "BNB",
      explorerUrl: "https://bscscan.com",
      rpcUrl: "https://bsc-dataseed1.binance.org/",
    },
    // {
    //   chainId: 10,
    //   name: "Optimism",
    //   currency: "ETH",
    //   explorerUrl: "https://optimistic.etherscan.io",
    //   rpcUrl: "https://mainnet.optimism.io",
    // },
  ];

  const ethersConfig = defaultConfig({
    metadata: {
      name: "Web3Modal",
      description: "Web3Modal Laboratory",
      url: "https://web3modal.com",
      icons: ["https://avatars.githubusercontent.com/u/37784886"],
    },
    defaultChainId: 1,
    rpcUrl: "https://cloudflare-eth.com",
  });

  const projectId = "d54225f2b745b8a5ade8db259d224f88";

  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
    enableAnalytics: true,
    themeMode: "light",
    themeVariables: {
      "--w3m-color-mix": "#00DCFF",
      "--w3m-color-mix-strength": 20,
    },
  });
  const modal = useWeb3Modal();

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();
  const handleModelOpen = () => {
    modal.open();
  };

  const [openMenu, setopenMenu] = useState(null);
  console.log("CHAIN ID", chainId);
  return (
    <MainSection>
      <Navbar expand="lg">
        <MainContainer>
          <Navbar.Brand
            onClick={() => window.open("https://www.onschain.com/", "_blank")}
          >
            <Image src={logo} style={{ cursor: "pointer" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto"></Nav>
            {isConnected ? (
              <ButtonWrapper
                className="ml-auto"
                style={{
                  transform: "translateY(22px)",
                }}
                onMouseLeave={() => setopenMenu(false)}
              >
                <ConnectBtn
                  className="connectWallet"
                  onMouseOver={() => setopenMenu(true)}
                >
                  {" "}
                  {CommonUtility.addressConvertor(address)}
                </ConnectBtn>
                <ConnectBtn
                  className={openMenu ? "disBtn active" : "disBtn"}
                  onClick={() => disconnect()}
                >
                  Disconnect Wallet
                </ConnectBtn>
              </ButtonWrapper>
            ) : (
              <ConnectBtn onClick={handleModelOpen}>Connect Wallet</ConnectBtn>
            )}
          </Navbar.Collapse>
        </MainContainer>
      </Navbar>
    </MainSection>
  );
};

export default NavbarComp;
