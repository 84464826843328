import styled from "styled-components";
import bgImg from "assets/images/bg.png";

export const MainSection = styled.section`
  .main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .main-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 50px 0;

    .textDescription {
      color: white;
      font-family: Inter;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;

export const Title = styled.h5`
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 42.64px; /* 118.444% */
  text-transform: capitalize;
  text-align: start;
  margin-top: 1rem;
  // padding-bottom: 1.5rem;
  span {
    color: #ffa701;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 42.64px;
    text-transform: capitalize;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

export const CardDiv = styled.div`
  .swapBtn {
    background: linear-gradient(0deg, #f0a500, #f0a500),
      linear-gradient(
        177.54deg,
        rgba(255, 255, 255, 0.1) 13.38%,
        rgba(255, 255, 255, 0) 97.94%
      );
    padding: 5px 30px;
    border: none;
    border-radius: 6px;
    color: white;
    font-size: 14px;

    margin: 20px 0;
    z-index:2;
  }
  .recieveToken {
    margin-top: -0.4rem;
  }
  .swapIconBtn {
    background: linear-gradient(0deg, #f0a500, #f0a500),
      linear-gradient(
        177.54deg,
        rgba(255, 255, 255, 0.1) 13.38%,
        rgba(255, 255, 255, 0) 97.94%
      );
    padding: 8px 10px;
    border: none;
    border-radius: 6px;
    color: white;

    margin-top: -10px;
    z-index: 2;
  }

  .swapDescription {
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0em;
    text-align: center;
  }

  .input-wrapper {
    position: relative;
    margin: 10px;
  }

  .input-field {
    border: 1px solid #d5dfd5;
    border-radius: 6px;
    background-color: transparent;
    width: 200px;
    color: white;
    z-index: 0;
    padding: 5px;
  }
  .input-field:focus {
    // border: 1px solid #d5dfd5;

    outline: none;
    box-shadow: none;
  }

  margin-top: 4rem;
  
  .label {
    position: absolute;
    top: -12px;
    left: 5px;
    color: white;
    padding: 0 5px;
    z-index: 3;
    background: #2b2b2b;
  }
 .cardWrapper
 {

  // background-color: rgba(255,255,255,.25);
  // backdrop-filter: blur(6px);
  width: 100%;
  
  padding: 0rem 1.5rem;
  margin-top: 4rem;
  margin-bottom: 2rem !important;
 }
  
.box {
  --border-width: 2px;
  
  background: rgba(178, 171, 171, 0.1);
  backdrop-filter: blur(16.399999618530273px);


  width: 100%;
  min-height: 349px;

  border: solid var(--border-width) transparent;
  
  // height: 100px;
  // width: 100px;
}

#myBox {
  
  // background: rgba(178, 171, 171, 0.1);
  // backdrop-filter: blur(16.399999618530273px);

  --angle: 0deg;

  border-image: conic-gradient(from var(--angle), black, cyan) 1;
  animation: 4s rotate-border linear infinite;
  

     
  background-size: cover;
  background-position: 0px;

}

#myBox2 {
  --angle: 0deg;
  --duration: 8s;
  --glow: 12px;
  --blur: 16px; /* Added blur variable */
  border-radius: 15px;
  background: 
  linear-gradient(#201f1f, #201f1f),
  conic-gradient(from var(--angle), black 0%, #F0A500 95%, black 100%),
  url(${bgImg}) no-repeat;
  background-size: cover;

 
    
  background-origin: border-box;
  background-clip: content-box, border-box;
   animation: var(--duration) rotate-border linear infinite;
  
  position: relative;
}

#myBox2:after {
  --angle: 0deg;
  background-clip: content-box, border-box;
  background-image: linear-gradient(black, black),
    // conic-gradient(
    //   from var(--angle),
    //   transparent 92%,
    //   #F0A500 95%,
    //   transparent 98%
    // );
    // border: 1.45px solid;
    

 

  background-origin: border-box;
  border: solid var(--glow) transparent;
  border-radius: 15px;
  content: "";
  height: calc(100% - 2 * var(--border-width));
  width: calc(100% - 2 * var(--border-width));
  top: calc(-1 * var(--glow) + var(--border-width));
  left: calc(-1 * var(--glow) + var(--border-width));
  animation: var(--duration) rotate-border linear infinite;
  position: absolute;
  z-index: -2;
}
#myBox2:before {
  box-shadow: inset 0 0 10px 10px var(--bg-color);
  content: "";
  height: calc(100% + 2 * var(--glow));
  width: calc(100% + 2 * var(--glow));
  position: absolute;
  top: calc(-1 * var(--glow));
  left: calc(-1 * var(--glow));
}

@keyframes rotate-border {
  to {
    --angle: 360deg;
    --rotation-angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";  
  initial-value: 0deg;
  inherits: false;
}

@property --rotation-angle {
  syntax: "<angle>";  
  initial-value: 0deg;
  inherits: false;
}

 
  
  .info-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.3rem;
    h5 {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 21.627px; /* 135.169% */
      margin-bottom: 0rem;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    p {
      color: #f3f3f3;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.627px; /* 135.169% */
      margin-bottom: 0rem;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
`;

export const Name = styled.h5`
  color: #ffa701;
  font-size: 25.646px;
  font-style: normal;
  font-weight: 600;
  line-height: 38.424px; /* 149.823% */
  text-transform: capitalize;
  text-align: center;
  margin-top: 2rem;
`;

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 2rem;
  padding-bottom: 2rem;
`;

export const StartButton = styled.button`
  color: #f0a500;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 5px;
  border: 1px solid #f0a500;
  width: 140px;
  height: 39px;
  display: flex;
  justify-content: center;
  item-align: center;
  align-items: center;
  gap: 0.5rem;
  background-color: transparent;

  @media (max-width: 990px) {
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    border-radius: 5px;
    border: 0.989px solid #f0a500;
    background: #f0a500;
    color: #fff;
  }
`;
